import React, { FC, useState, useEffect } from "react"
// import { Link } from "gatsby"
import { Tabs } from "antd"
import VisibilitySensor from "react-visibility-sensor"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import { MediaCard } from "../components/Media"
import { LoginHeader } from "../components/nav";
import { SocialHeader } from "../components/social";
import "antd/dist/antd.css"

const { TabPane } = Tabs

const tabMap = {
  "in-the-news": "In the News",
  // 'company-media-kit': 'Company Media Kit',
  "happy-videos": "Happy Videos",
  "mango-slice-blog": "Mango Slice Blog",
}

const data = [
  {
    url: `https://www.moneygeek.com/personal-loans/bad-credit/#expert=kate-hao`,
    img: `/images/moneygeek.png`,
    date: `July 6, 2023`,
    desc: `How to get a personal loan even with bad credit`,
  },
  {
    url: `https://fintecbuzz.com/fintech-interview-with-kate-hao/`,
    img: `/images/fintechbuzz.png`,
    date: `May 16, 2023`,
    desc: `Find out Happy Mango’s ambitious future plans in this featured interview`,
  },
  {
    url: `https://mwg.aaa.com/via/your-money/how-to-read-credit-report-line-by-line`,
    img: `/images/aaa.png`,
    date: `May 8, 2023`,
    desc: `How to read a credit report according to Happy Mango and other experts`,
  },
  {
    url: `https://www.pewtrusts.org/en/research-and-analysis/articles/2023/03/31/credit-union-small-dollar-loan-volume-hit-new-high-in-2022`,
    img: `/images/pew.png`,
    date: `March 31, 2023`,
    desc: `Happy Mango’s technology helps drive Small-Dollar Loan Volume at Credit Unions`,
  },
  {
    url: `https://vimeo.com/795389619`,
    img: `/images/AsureLogoBlack.jpg`,
    date: `February 2, 2023`,
    desc: `Happy Mango founder featured on Asure webinar on How To Fund Your Small Business`,
  },
  {
    url: `https://www.consumeraffairs.com/finance/low-income-loans.html`,
    img: `/images/consumeraffairs.png`,
    date: `December 10, 2022`,
    desc: `Happy Mango Weighs In on Low-Income Personal Loans`,
  },
  {
    url: `https://thefintechtimes.com/fintech-trends-in-2022-with-revolv3-finreg3-happymango-jifiti/`,
    img: `/images/fintech2022.jpeg`,
    date: `December 9, 2022`,
    desc: `“FinTech for Good” – Happy Mango talks what was trendy in 2022`,
  },
  {
    url: `https://www.youtube.com/watch?v=YG3GR1p8d6o`,
    img: `/images/CMBA2015LogoDesign.png`,
    date: `October 14, 2022`,
    desc: `Happy Mango is using technology and data to close the wealth gap`,
  },
  {
    url: `https://www.digitalcommerce360.com/2022/09/23/an-ecommerce-tech-entrepreneur-helps-small-businesses-get-financing/`,
    img: `/images/d360.png`,
    date: `September 23, 2022`,
    desc: `Happy Mango is helping small businesses get affordable financing`,
  },
  {
    url: `https://www.nasdaq.com/videos/servicing-the-credit-invisible`,
    img: `/images/nasdaq.png`,
    date: `September 15, 2022`,
    desc: `Happy Mango shares how financial products could help close the wealth gap`,
  },
  {
    url: `https://www.americanbanker.com/news/reaching-the-invisibles`,
    img: `/sites/default/files/americanbanker.png`,
    date: `August 16, 2022`,
    desc: `Happy Mango explains how technology helps financial institutions reach the credit invisible`,
  },
  {
    url: `https://money.usnews.com/credit-cards/articles/can-you-use-a-personal-credit-card-to-start-a-business`,
    img: `/images/usnews.png`,
    date: `May 25, 2022`,
    desc: `Should you start a small business with your personal credit card?`,
  },
  {
    url: `https://bottomlineinc.com/life/consumer-technology/credit-score-apps-not-all-theyre-cracked-up-to-be`,
    img: `/images/bottomlineinc.png`,
    date: `May 22, 2022`,
    desc: `Which Credit Score App is the most beneficial for you?`,
  },
  {
    url: `https://www.moneygeek.com/credit-cards/bad-credit/resources/practical-steps-to-improve-credit/#expert=kate-hao`,
    img: `/images/moneygeek.png`,
    date: `Apr 5, 2022`,
    desc: `Credit Score is important. What can you do to improve it?`,
  },
  {
    url: `https://money.usnews.com/money/personal-finance/saving-and-budgeting/articles/cost-of-living-how-to-calculate-how-much-you-need`,
    img: `/images/usnews.png`,
    date: `Mar 23, 2022`,
    desc: `With recession looming, it is important to know your Cost of Living`,
  },
  {
    url: `https://www.cnbc.com/2022/02/03/there-have-never-been-more-ways-to-establish-and-build-a-credit-score.html`,
    img: `/images/cnbc.png`,
    date: `Feb 3, 2022`,
    desc: `What are the different ways to improve credit score?`,
  },
  {
    url: `https://www.cutimes.com/2022/01/18/expanding-membership-increasing-revenue-while-solving-one-of-the-biggest-social-challenges/?slreturn=20220018100928`,
    img: `/images/credit_union_times.png`,
    date: `Jan 18, 2022`,
    desc: `How can Credit Union solve the underbanked problem?`,
  },
  {
    url: `https://moneylifeshow.libsyn.com/axel-merk-near-term-concerns-cant-be-ignored`,
    img: `/images/media_money.png`,
    date: `Jan 13, 2022`,
    desc: `What is the impact of large bank cutting fees?`,
  },
  {
    url: `https://shows.acast.com/74west-exclusives/episodes/revising-risk-assessment-how-happy-mango-is-creating-more-eq`,
    img: `/images/media_74w.png`,
    date: `Dec 14, 2021`,
    desc: `Technology Enables Equitable Access to Financial Services`,
  },
  {
    url: `https://medium.com/authority-magazine/female-disruptors-kate-hao-of-happy-mango-on-the-three-things-you-need-to-shake-up-your-industry-b17e9ec05897`,
    img: `/images/media_authority.png`,
    date: `Nov 26, 2021`,
    desc: `How Happy Mango disrupts the financial services industry`,
  },
  {
    url: `https://thefintechtimes.com/women-in-fintech-vanquis-tomocredit-mission-lane-goodbox-happy-mango-blueshore-financial/`,
    img: `/images/media_fintech.png`,
    date: `Oct 31, 2021`,
    desc: `Happy Mango founder featured in "Women in Fintech"`,
  },
  {
    url: `https://www.nasdaq.com/articles/seeing-the-credit-invisible-2021-10-29`,
    img: `/images/media_nasdaq.png`,
    date: `Oct 29, 2021`,
    desc: `Happy Mango proposes solutions for "seeing the credit invisible"`,
  },
  {
    url: `https://www.americanbanker.com/news/let-well-capitalized-small-banks-play-in-the-sandbox-panelists`,
    img: `/sites/default/files/americanbanker.png`,
    date: `Oct 5, 2018`,
    desc: `Happy Mango makes proposal to U.S. banking regulators`,
  },
  {
    url: `http://www.greensheet.com/breakingnews.php?flag=breaking_news&amp;id=1703`,
    img: `/sites/default/files/greensheet.png`,
    date: `June 21, 2016`,
    desc: `Happy Mango commented on latest trend on consumer debt`,
    width: 50,
  },
  {
    url: `http://www.bxtimes.com/stories/2016/7/07-springbank-2016-02-12-bx.html`,
    img: `/sites/default/files/bronxtimes.png`,
    date: `Feb 14, 2016`,
    desc: `Happy Mango helps bank seek out ‘under-banked’ customers`,
  },
  {
    url: `http://www.fastcoexist.com/3054605/this-startup-wants-to-demystify-credit-scoring-and-open-up-loans-to-more-people`,
    img: `/sites/default/files/fastcoexist.png`,
    date: `Jan 4, 2016`,
    desc: `Happy Mango Wants To Demystify Credit Scoring`,
  },
  {
    url: `http://www.cutimes.com/2015/11/18/social-media-for-credit-scoring`,
    img: `/images/credit_union_times.png`,
    date: `Nov 18, 2015`,
    desc: `Is Social Media for Credit Scoring going to work?`,
  },
  {
    url: `http://money.usnews.com/money/personal-finance/articles/2015/06/23/can-alternative-credit-scoring-models-help-millions-of-consumers`,
    img: `/sites/default/files/us-news_0_0.png`,
    date: `Jun 23, 2015`,
    desc: `Companies currently developing alternative credit scoring models`,
  },
  {
    url: `http://www.washingtonpost.com/news/get-there/wp/2015/04/02/a-new-kind-of-credit-score-for-those-with-no-credit/`,
    img: `/images/thewashingtonpost-white-2x.png`,
    date: `Apr 2, 2015`,
    desc: `How Happy Mango helps consumers do better financially`,
    bg: true,
  },
  {
    url: `http://www.usatoday.com/story/money/2015/03/30/alternative-credit-scoring-models/70324558/`,
    img: `/sites/default/files/usa-today_0_0.png`,
    date: `Mar 30, 2015`,
    desc: `How Happy Mango was conceived and where it is headed.`,
  },
  {
    url: `http://abcnews.go.com/Business/buy-good-credit-score/story?id=25952113#2`,
    img: `/sites/default/files/abc-news_0_0.png`,
    date: `Oct 4, 2014`,
    desc: `How conventional credit score works with quotes from CEO`,
    width: "100",
  },
]

const defaultKey = "in-the-news"
let mediaTitle = tabMap["in-the-news"]

export function Banner_img() {
  return <StaticImage src="../../static/images/jobs-hd.jpg" alt="banner" />
}
export default () => {
  const [mediaState, setMediaState] = useState({ tabKey: defaultKey })
  const [mediaTabFixed, setMediaTabFixed] = useState(false)

  const onChange = key => {
    setMediaState({ tabKey: key })
  }

  useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
    mediaTitle = tabMap[mediaState.tabKey]
    document.querySelector("#bannerInfo").innerHTML = mediaTitle
    window.location.hash = `#${mediaState.tabKey}`
  })

  const onVisibleChange = isVisible => {
    console.log("Element is now %s", isVisible ? "visible" : "hidden")
    if (isVisible) {
      setMediaTabFixed(false)
    } else {
      setMediaTabFixed(true)
    }
  }

  return (
    <>
      <Header titleText="Media - Happy Mango" />
      <div className={`main ${mediaTabFixed ? "fixed" : ""}`}>
        <div className="PC_head">
          <LoginHeader />
          <SocialHeader />
          <div className="logo_div">
            <a rel="" href="/" className="logo" title="Happy Mango">
              <img
                className="light"
                src="/images/hm-logo-v2.png"
                alt="Happy Mango"
              />
            </a>
          </div>
          <ul className="nav_ul">
            <li className="nav_li">
              <a rel="" href="/services/">
                Our Services
              </a>
              <ul className="nav_ul_son">
                <li className="nav_li_son">
                  <a rel="" href="/services/#Digitalize">
                    Front to Back Digitalization
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/services/#Customize">
                    Customized Underwriting
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/services/#Expand">
                    Digital Marketing Tools
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/services/#Improve">
                    Financial Health Management
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/services/#Track">
                    Impact Analysis and Reporting
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav_li">
              <a rel="" href="/clients/">
                Our Clients
              </a>
              <ul className="nav_ul_son nav_ul_son_se">
                <li className="nav_li_son">
                  <a rel="" href="/clients/#clients_top">
                    Banks
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/clients/#clients_top">
                    Credit Unions
                  </a>
                </li>
                <li className="nav_li_son">
                  <a rel="" href="/clients/#clients_top">
                    CDFIs
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav_li">
              <a rel="" href="/why/">
                Why Happy Mango?
              </a>
            </li>
          </ul>
        </div>

        <Head />

        <div className="banner_contact">
          <img
            className="contact_img"
            src="/images/fresh-leaves.jpg"
            alt="banner"
          />
          <h1 className="bannerinfo" id="bannerInfo">
            {mediaTitle}
          </h1>
        </div>
        <VisibilitySensor onChange={onVisibleChange}>
          <div
            className="sensor"
            style={{
              display: "block",
              position: "absolute",
              bottom: 0,
              "z-index": 1,
              left: 0,
              right: 0,
              height: "2px",
              top: "390px",
              // border: '1px solid red',
            }}
          ></div>
        </VisibilitySensor>

        <div className="media">
          <Tabs defaultActiveKey="1" onChange={onChange} className="media_tabs">
            <TabPane tab={tabMap["in-the-news"]} key="in-the-news">
              <div className="media_press">
                <div className="media_card_container">
                  {data.map(item => {
                    const {
                      url,
                      img,
                      date,
                      desc,
                      bg = false,
                      logoAutoHeight = false,
                      width = null,
                    } = item
                    return (
                      <MediaCard
                        url={url}
                        img={img}
                        date={date}
                        desc={desc}
                        bg={bg}
                        width={width}
                        logoAutoHeight={logoAutoHeight}
                      />
                    )
                  })}
                </div>
              </div>
            </TabPane>
            <TabPane tab={tabMap["happy-videos"]} key="happy-videos">
              <div className="contact">
                <div className="contact_form">
                  <video
                    src="/videos/HappyMango2023.mp4"
                    poster="/videos/HappyMango2023.png"
                    onError="movie.ogg"
                    controls="controls"
                  >
                    Your browser does not support video tags.
                  </video>
                </div>
              </div>
            </TabPane>
            <TabPane tab={tabMap["mango-slice-blog"]} key="mango-slice-blog">
              <div className="grid-container">
                <div className="row">
                  <div className="col_6">
                    <a href="/blog/Help-Borrowers-Pay-off">
                      <div className="items_div">
                        <div className="col_6">
                          <h3>
                            How does Happy Mango help borrowers pay off their
                            loans?
                          </h3>
                          <br />
                          <p>
                            Happy Mango helps borrowers successfully pay back
                            their loans by providing the flexibility and support
                            that they need though technology.
                          </p>
                          <div>
                            <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" />
                            September 1, 2022
                          </div>
                        </div>
                        <div className="col_6">
                          <img
                            src="/sites/all/themes/hmm/images/blogs/payoffloans.jpg"
                            className="itemimg"
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </a>
                  </div>
                  <div className="col_6">
                    <a href="/blog/Record-Profits-Invest-In-Your-Future">
                      <div className="items_div">
                        <div className="col_6">
                          <h3>Record Profits? Invest In Your Future</h3>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p>
                            Banks had record profits in 2018. Now is the time to
                            deploy that capital to invest in the future.
                          </p>
                          {/* <p>
              Community banks should seize the moment to innovate in product and
              process
            </p>
            <p>
              2018 is shaping out to be a banner year for community banks.
              Record profits, a solid and growing capital base, major regulatory
              relief – there are no looming threats that might reverse these
              favorable trends. Nevertheless, the number of community banks
              continues to decline and competitive pressure from credit unions
              and large banks appears to only intensify. <b
                >Now is the time to deploy some of those record profits and
                excess capital to securing your future.</b
              >
            </p> */}
                          <div>
                            <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" />
                            Oct 19, 2018
                          </div>
                        </div>
                        <div className="col_6">
                          <img
                            src="/sites/all/themes/hmm/images/blogs/0.jpg"
                            className="itemimg"
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </a>
                  </div>
                  <div style={{ clear: "both" }}>
                    <div className="col_4 items">
                      <a href="/blog/Comments-to-NCUA">
                        <div className="items_div">
                          <img
                            src="/sites/all/themes/hmm/images/blogs/1.jpg"
                            className="itemimg"
                          />
                          <h3>
                            NCUA’s New Proposed Rule on Payday Alternative Loans
                          </h3>
                          <p>
                            Happy Mango Submitted Comments to NCUA on its
                            Proposed Payday Alternative Loans, urging greater
                            flexibility to make affordable loans a sustainable
                            business.
                          </p>
                          <div>
                            <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" />
                            Aug 17, 2018
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col_4 items">
                      <a href="/blog/Good-Profits-for-Good-Loans">
                        <div className="items_div">
                          <img
                            src="/sites/all/themes/hmm/images/blogs/2.jpg"
                            className="itemimg"
                          />
                          <h3>
                            Good Profits for Good Loans
                            <br />
                            &nbsp;
                          </h3>
                          <p>
                            More than 50 million Americans with low or no credit
                            scores cannot access credit from mainstream
                            lenders.  An opportunity for lenders to do good and
                            do well.
                          </p>
                          <div>
                            <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" />
                            Nov 17, 2017
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col_4 items">
                      <a href="/blog/Middle-Income-Consumers-Demand-Small-Dollar-Loans">
                        <div className="items_div">
                          <img
                            src="/sites/all/themes/hmm/images/blogs/3.jpg"
                            className="itemimg"
                          />
                          <h3>
                            Middle-Income Consumers Demand Small-Dollar Loans
                          </h3>
                          <p>
                            Our user data indicate that middle-income consumers
                            prefer small-dollar loans for managing short-term
                            cash flow volatility because they are easy to manage
                            and easy to control.
                          </p>
                          <div>
                            <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" />
                            Sept 30, 2016
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>

        <footer>
          <Footer />
        </footer>
      </div>
      <script src="/js/media_page.js"></script>
    </>
  )
}
