import React from "react"

export const MediaCard = props => {
  const { url, img, date, desc, bg, logoAutoHeight, width } = props;
  let classes = '';
  classes = bg ? `has_background` : '';
  classes += logoAutoHeight ? ` logo_auto_height` : ' logo_fixed_height';
  return (
    <div className="media_card" onClick={(e) => {
      e.preventDefault();
      // window.location.href=url;
      window.open(url, '_blank');
      }}
    >
      <div className="media_card_logo">
        <a href={url}>
          <img typeof="foaf:Image" src={img} width={width?width:'147'} alt="" className={classes} />
        </a>
      </div>
      <div className="media_card_date">
        <p className="media_press_date">{date}</p>
      </div>
      <div className="media_card_body">
        <div className="">
          <p>{desc}</p>
        </div>
      </div>
    </div>
  )
}
